@import "reset";
@import "base";
@import "setting";
.disply{
 opacity: 1 !important;
  transition: 400ms linear all;
}
.display-flex{
  display: flex !important;
}


.cabinet{
  position: fixed;
  top: 18px;
  right: 22px;
  z-index: 1;
  cursor: pointer;
  p{
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    img{
      margin-right: 10px;
      width: 22px;
    }
  }
  form{
    position: absolute;
    right: 0;
    top: 70px;
    padding: 30px 30px;
    background: #f7f7f7;
    border-radius: 15px;
    width: 283px;
    opacity: 0;
    transition: 200ms linear all;
    .back {
      margin-bottom: 15px;
      input{
        width: 100%;
        border: 1px solid #bbbbbb;
        padding: 12px 22px;
        font-weight: bold;
        font-size: 12px;
        border-radius: 2px;
        color: #000;
      }
    }
    button{
      display: block;
      margin: 0 auto;
      background: #E42239;
      border: none;
      color: #fff;
      width: 80%;
      border-radius: 5px;
      padding: 9px;
      box-shadow: 0px 0px 8px 0px #e42239b8;
    }
  }
}
.popup{
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //z-index: 999;
  align-items: center;
  height: 100vh;
  overflow: auto;
  max-height: 100vh;
  display: flex;
  @media (max-height: 950px){
  align-items: flex-start;
    padding-top: 110px;
    padding-bottom: 1px;
}

  .content-popup{
    width: 60%;
    height: 69%;
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    @media (max-height: 1000px){
    //height: 873px;
  }
    @media (max-width: 1000px){
      width: 90%;
      //height: 49%;
      height: 90%;
    }
    p{
      margin-bottom: 0;
      font-size: 55px;
      font-weight: 900;
      text-align: center;
      line-height: 72px;
      width: 70%;
      @media (max-width: 1300px){
        font-size: 25px;
        line-height: 42px;
      }
      @media (max-width: 800px){
        font-size: 18px;
        line-height: 26px;
      }
    }
    a{
      background: #E42239;
      padding: 11px 67px;
      border-radius: 10px;
      margin-top: 154px;
      font-size: 50px;
      color: #fff;
      @media (max-width: 1300px){
        font-size: 25px;
        margin-top:34px;
      }
      @media (max-width: 800px){
        font-size: 12px;
      }
    }
  }
}