// Reset
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: flex !important;
}
html,
body {
  overflow-x: hidden;
}
#main_container {
  overflow: hidden;
}
.kat-container {
  width: 1220px;
  position: relative;
  margin: 0 auto;
  @media (max-width: 1245px) {
    width: 1070px
  }
  @media (max-width: 1088px) {
    width: 970px;
  }
  @media (max-width: 987px) {
    width: 100%;
    padding: 0 10px;
  }
}
#main_content {
  width: 100%;
  position: relative;
  transition: .2s linear;
}
a {
  text-decoration: none;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}
p, h1, h2, h3, h4, h5 {
  margin: 0
}
p, a, span, h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', sans-serif;
}
.overflow-hiden{
  overflow: hidden !important;
}
.right{
  right: 0 !important;
}
a ,input , button , textarea{
  transition: 300ms linear all;
}
a , input , button, textarea{
  transition: 300ms linear all;
}
a:hover , a:focus , input:hover , button:hover, textarea:hover {
  cursor: pointer;
  text-decoration: none !important;
  opacity: 0.5;
}
.navbar-mobile-menu {
  z-index: 11111111;
  display: none;
  @media (max-width: 992px) {
    display: block;
    background: transparent;
    padding: 10px 13px;
    position: relative;
    right: 10px;
    top: 0;
    margin-left: auto;
  }
  .no-overflow {
    overflow: hidden !important;
  }
  .menu-top-click {
    backface-visibility: hidden;
    top: 22px !important;
    transform: rotate(46deg);
    transition: 0.55s 0.5s !important;
  }
  .menu-middle-click {
    opacity: 0;
  }
  .menu-bottom-click {
    backface-visibility: hidden;
    top: 22px !important;
    transform: rotate(-406deg);
    transition: 0.55s 0.5s !important;
  }
  .line-1-open {
    transform: translateX(-11px) rotate(-45deg) translateY(12px);
    background-color: rgb(38, 66, 140) !important;
  }
  .line-2-open {
    opacity: 0;
    background-color: rgb(38, 66, 140) !important;
  }
  .line-3-open {
    transform: translateX(-8px) rotate(45deg) translateY(-8px);
    background-color: rgb(38, 66, 140) !important;
  }
  .lines-open {
    position: relative;
    cursor: pointer;
    width: 23px;
    height: 18px;
    .line {
      position: absolute;
      display: block;
      background: rgb(38, 66, 140);
      width: 18px;
      height: 2px;
      border-radius: .2rem;
      right: 0;
      -webkit-transition: all .6s cubic-bezier(.5, .1, 0, 1.2);
      transition: all .6s cubic-bezier(.5, .1, 0, 1.2);
    }
    .line-1 {
      top: 0;
    }
    .line-2 {
      top: 7px;
      width: 23px;
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
    .line-3 {
      top: 14px;
    }
  }

}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}
q {
  &:before, &:after {
    content: '';
    content: none;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ascunde {
  display: none;
}
.arata {
  display: block;
}